import React from "react"
import styles from "../productDetail.module.scss"
import { Link } from "gatsby"
import Menubar from "../../components/menubar/menubar"
import { useStaticQuery, graphql } from "gatsby"
import Carousel from "react-bootstrap/Carousel"
import Footer from "../../components/footer/footer"
import ProjectTopImage from "../../img/proje_975.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons"

const YesilyurtDC = () => {
  const data = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "yesilyurtDC1.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      two: file(relativePath: { eq: "yesilyurtDC2.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      three: file(relativePath: { eq: "yesilyurtDC3.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      four: file(relativePath: { eq: "yesilyurtDC4.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      five: file(relativePath: { eq: "yesilyurtDC5.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      six: file(relativePath: { eq: "yesilyurtDC6.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  `)
  return (
    <div className={styles.productDetailContainer}>
      <div className={styles.leftE}></div>
      <div className={styles.centerSite}>
        <Menubar />
        <div className={styles.imageTopProductDetailContainer}>
          <img
            src={ProjectTopImage}
            alt="projects"
            className={styles.imageTopProductDetail}
          />
          <div className={styles.verticalLineWhiteProductDetail}>
            <div className={styles.socialMediaIcons}>
              <a
                href="https://www.facebook.com/atiaahsap"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faFacebookF}
                  size="1x"
                  className={styles.ffIcon}
                />
              </a>
              <br />
              <a
                href="https://www.instagram.com/atiaahsap/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  size="1x"
                  className={styles.igIcon}
                />
              </a>
            </div>
          </div>
          <span className={styles.topSpanProductDetail}>
            YEŞİLYURT DEMİR ÇELİK <br />
            YÖNETİM BİNASI
            <br />
            <Link to="/projeler" className={styles.backLinkPD}>
              TÜM PROJELER <FontAwesomeIcon icon={faChevronRight} />
            </Link>
          </span>
        </div>
        <div className={styles.pdContainer}>
          <div className={styles.pDmiddleContainer}>
            <Carousel indicators={false} fade={true} id="pdetailCar">
              <Carousel.Item>
                <img
                  src={data.one.childImageSharp.fluid.src}
                  alt="first of item detail"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={data.two.childImageSharp.fluid.src}
                  alt="second of item detail"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={data.three.childImageSharp.fluid.src}
                  alt="third of item detail"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={data.four.childImageSharp.fluid.src}
                  alt="four of item detail"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={data.five.childImageSharp.fluid.src}
                  alt="five of item detail"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={data.six.childImageSharp.fluid.src}
                  alt="six of item detail"
                />
              </Carousel.Item>
            </Carousel>
            <div className={styles.PDMiddleTextBox}>
              <table>
                <tbody>
                  <tr>
                    <td>Proje Adı:</td>
                    <td>Yeşilyurt Demir Çelik Yönetim Binası</td>
                  </tr>
                  <tr>
                    <td>Proje Türü:</td>
                    <td>Yönetim Binası</td>
                  </tr>
                  <tr>
                    <td>Proje Yeri:</td>
                    <td>Samsun</td>
                  </tr>
                  <tr>
                    <td>Tasarım:</td>
                    <td>Abdullah Gencaslan</td>
                  </tr>
                  <tr>
                    <td>Fotoğraf:</td>
                    <td>Abdullah Gencaslan</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <div className={styles.rightE}></div>
    </div>
  )
}

export default YesilyurtDC
